import { Controller } from "stimulus";
import Croppie from 'croppie';
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [
    "mainForm",
    "mainPointsDisplay",
    "qualifyingPointsDisplay",
    "qualifyingPointsHeader",
    "enableQualifyingData",
    "gameId",
    "maxDrivers",
    "existingPoints",
    "imageModal",
    "imageField",
    "imageCropper",
    "advancedOptionsDiv",
    "vehicleClassesSelect",
    "carSelect",
    "pointSystems",
    "pointSystemLi",
    "pointSystemSelector",
    "multiplePointSwitch"
  ];

  connect() {
    this.communityManaged = (this.mainFormTarget.dataset.communityManaged == 'true');
    if(this.communityManaged) {
      this.defaultMainPoints = JSON.parse(this.existingPointsTarget.dataset.mainpoints);
      this.defaultQualifyingPoints = JSON.parse(this.existingPointsTarget.dataset.qualifyingpoints);
      const maxDrivers = this.defaultMainPoints.length;
      this.createPointsInputs(maxDrivers);

      if(this.enableQualifyingDataTarget.checked) {
        this.createQualifyingPointsInputs(maxDrivers);
      }
      if(this.hasPointSystemsTarget) {
        this.sortable = Sortable.create(this.pointSystemsTarget, {
          onEnd: this.updatePointSystemPositions.bind(this)
        });
      }
    }
    this.setupCroppy();
  }


  setupCroppy() {
    this.croppedImage = null;
    this.croppie = new Croppie(this.imageCropperTarget, {
      viewport: { width: 350, height: 350, type: 'square'},
      boundary: { width: 500, height: 500 },
      enableZoom: true,
      showZoomer: true,
      enableOrientation: false,
      mouseWheelZoom: 'ctrl'
    });
  }

  showCropperModal(event) {
    this.imageModalTarget.classList.remove("hide");
    var url = URL.createObjectURL(event.target.files[0]);
    this.croppie.bind({url: url});
  }

  saveCroppedImage() {
    this.croppie.result("base64").then((base64) => {
      this.imageFieldTarget.value = JSON.stringify({data: base64});
    });
    this.imageModalTarget.classList.add("hide");
  }

  clearPreviousInputs() {
    while (this.mainPointsDisplayTarget.firstChild) {
      this.mainPointsDisplayTarget.removeChild(this.mainPointsDisplayTarget.firstChild);
    }
  }

  removeQualifyingPointsInputs() {
    while (this.qualifyingPointsDisplayTarget.firstChild) {
      this.qualifyingPointsDisplayTarget.removeChild(this.qualifyingPointsDisplayTarget.firstChild);
    }
  }

  changePointSystem(e) {
    if(e.target.value) {
      fetch(`/api/v1/point_systems/${e.target.value}`).then(response => response.json()).then(data => {
        this.existingPointsTarget.dataset.mainpoints = data["main_points"];
        this.defaultMainPoints = data["main_points"];
        this.existingPointsTarget.dataset.qualifyingpoints = data["qualifying_points"];
        this.defaultQualifyingPoints = data["qualifying_points"];
        this.enableQualifyingDataTarget.value = data["enable_qualifying_data"];
        if(data["enable_qualifying_data"]) {
          this.qualifyingPointsHeaderTarget.classList.remove("hide");
          this.createQualifyingPointsInputs(data["max_drivers"]);
          this.enableQualifyingDataTarget.checked = true;
        } else {
          this.qualifyingPointsHeaderTarget.classList.add("hide");
          this.removeQualifyingPointsInputs();
          this.enableQualifyingDataTarget.checked = false;
        }
        this.createPointsInputs(data["max_drivers"]);
      });
    }
  }

  createPointsInputs(maxDrivers) {
    this.clearPreviousInputs()
    for (var i = 0; i <= (maxDrivers - 1); i++) {
      var defaultPoint = this.getMainPoint(i);
      let formGroup = document.createElement("div");
      formGroup.setAttribute("class", "input-group input-group-sm mb-3");
      let div = document.createElement("div");
      div.setAttribute("class", "input-group-prepend");
      let span = document.createElement("span")
      span.setAttribute("class", "input-group-text");
      const position = document.createTextNode(i+1);
      span.appendChild(position);
      div.appendChild(span)
      formGroup.appendChild(div)
      let input = document.createElement("input");
      input.setAttribute("type", "number");
      input.setAttribute("name", `league[main_points][]`);
      input.setAttribute("class", "form-control");
      input.setAttribute("value", defaultPoint);
      formGroup.appendChild(input);
      this.mainPointsDisplayTarget.appendChild(formGroup);
    }
  }

  createQualifyingPointsInputs(maxDrivers) {
    this.removeQualifyingPointsInputs();
    for (var i = 0; i <= (maxDrivers - 1); i++) {
      var defaultPoint = this.getQualifyingPoint(i);
      let formGroup = document.createElement("div");
      formGroup.setAttribute("class", "input-group input-group-sm mb-3");
      let div = document.createElement("div");
      div.setAttribute("class", "input-group-prepend");
      let span = document.createElement("span")
      span.setAttribute("class", "input-group-text");
      const position = document.createTextNode(i+1);
      span.appendChild(position);
      div.appendChild(span)
      formGroup.appendChild(div)
      let input = document.createElement("input");
      input.setAttribute("type", "number");
      input.setAttribute("name", `league[qualifying_points][]`);
      input.setAttribute("class", "form-control");
      input.setAttribute("value", defaultPoint);
      formGroup.appendChild(input);
      this.qualifyingPointsDisplayTarget.appendChild(formGroup);
    }
  }

  getMainPoint(i) {
    return (this.defaultMainPoints[i] || 0);
  }

  getQualifyingPoint(i) {
    return (this.defaultQualifyingPoints[i] || 0);
  }

  updateMaxDrivers(e) {
    this.createPointsInputs(e.target.value);
    if(this.enableQualifyingDataTarget.checked) {
      this.createQualifyingPointsInputs(e.target.value);
    }
  }

  toggleAdvancedOptions() {
    let div = this.advancedOptionsDivTarget;
    if(div.classList.contains("hide")){
      div.classList.remove("hide");
      this.multiplePointSwitchTarget.scrollIntoView();
    } else {
      div.classList.add("hide");
    }
  }

  updateCarSelect() {
    const vehicleClasses = Array.from(this.vehicleClassesSelectTarget.selectedOptions).map(opt => opt.value).join(",");
    const gameId = this.gameIdTarget.value;
    const url = `/api/v1/available_cars?game_id=${gameId}&vehicle_classes=${vehicleClasses}`
    let results = fetch(url).then(response => response.json()).then(data => {
      const selectInput = this.carSelectTarget;
      const car_ids = data.map(car => car[1]);
      Array.from(selectInput.options).forEach(opt => {
        if(car_ids.includes(opt.value)) {
          opt.selected = true;
        } else {
          opt.selected = false;
        }
      });
    });
  }

  clearCarSelect() {
    const selectInput = this.carSelectTarget;
    Array.from(selectInput.options).forEach(opt => { opt.selected = false });
  }

  toggleQualifyingPoints() {
    if(this.qualifyingPointsDisplayTarget.classList.contains("hide")) { return };
    let header = this.qualifyingPointsHeaderTarget;
    if(this.enableQualifyingDataTarget.checked){
      header.classList.remove("hide");
      this.createQualifyingPointsInputs(this.maxDriversTarget.value);
    } else {
      this.removeQualifyingPointsInputs();
      header.classList.add("hide");
    }
  }

  updatePointSystemPositions(event) {
    const positions = Array.from(this.pointSystemLiTargets).map((li,index) => {
      return {"id": li.dataset.pointSystemId, "position": index}
    });
    fetch(this.pointSystemsTarget.dataset.url,
      {
        method: "POST",
        body: JSON.stringify({point_systems: {sort: positions}}),
        headers: { 'Content-Type': 'application/json'}
      })
      .then(function(res){ return res.json(); })
      .then(function(data){ console.log( JSON.stringify( data ) ) });
  }

  addPointSystem(e) {
    if(e.target.value) {
      const url = e.target.dataset.url.replace("PSID", e.target.value);
      const payload = {league_id: e.target.dataset.leagueId}
      fetch(
        url,
        {
          method: "POST",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(payload)
        }
      ).then(res => {return res.json() }
      ).then(data => {
        const li = this.pointSystemLiTargets[0];
        let dupe = li.cloneNode(true);
        dupe.dataset.pointSystemId = data.id;
        let linkNode = dupe.getElementsByTagName("a")[0];
        linkNode.style.color = data.colour;
        linkNode.href = data.url;
        let textNode = dupe.getElementsByClassName("text")[0];
        textNode.innerHTML = data.name;
        this.pointSystemsTarget.appendChild(dupe);
      });
    }
  }

}
