import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  limitSelections(event) {
    let opts = Array.from(event.target.children);
    let total = 0
    opts.forEach(opt => {
      if(opt.selected) {
        total ++;
        if(total > 3) {
          opt.selected = false;
        }
      }
    })
  }
}
