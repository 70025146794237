import { Controller } from "stimulus";
import debounce from 'lodash/debounce'

export default class extends Controller {

  static targets = ["form", "search", "game"]

  connect() {
    this.search = debounce(this.search, 1000);
  }

  search(event) {
    const url = this.formTarget.action;
    const search = event.target.value;
    const gameId = this.gameTarget.value;
    window.location=`${url}?game_id=${gameId}&search=${search}`
  }

  changeGame(event) {
    const url = this.formTarget.action;
    const gameId = event.target.value;
    const search = this.searchTarget.value;
    window.location=`${url}?game_id=${gameId}&search=${search}`
  }

}
