import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["raceResultFields2", "raceResultFields3"];

  showFields(e) {
    e.preventDefault();
    if(e.target.dataset.result == 2) {
      this.raceResultFields2Target.classList.add("show");
      this.raceResultFields2Target.classList.remove("hide");
    } else {
      this.raceResultFields3Target.classList.add("show");
      this.raceResultFields3Target.classList.remove("hide");
    }
    e.target.classList.add("hide");
  }
}
