import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["table", "nameChanger", "name", "carName", "pointsChanger", "fullResultsButton", "fullResultsTd"];

  change(e) {
    let children = Array.from(this.nameChangerTarget.children);
    let table = this.nameChangerTarget.closest("table");
    let index = children.findIndex(el => Array.from(el.classList).includes("selected"));
    let nextIndex = index + 1;
    if(nextIndex == children.length) { nextIndex = 0 }
    children[index].classList.remove("selected");
    let oldClassName = children[index].className;
    children[index].classList.add("hide");
    children[nextIndex].classList.remove("hide");
    let className = children[nextIndex].className;
    children[nextIndex].classList.add("selected");
    var oldElements = table.querySelectorAll(`[data-username="${oldClassName}"]`)
    var newElements = table.querySelectorAll(`[data-username="${className}"]`)
    if(oldElements) {
      oldElements.forEach(el => el.classList.add("hide"))
    }
    if(newElements) {
      newElements.forEach(el => el.classList.remove("hide"))
    }
  }

  changeCarView() {
    this.carNameTargets.forEach(el => {
      el.classList.toggle("hide")
    });
  }

  changeFullResultsView(event) {
    this.fullResultsButtonTargets.forEach(button => button.classList.remove("current"));
    event.target.classList.add("current");
    const nameType = event.target.dataset.buttonName;
    this.fullResultsTdTargets.forEach((td) => {
      if(td.dataset.tdName == nameType) {
        td.classList.remove("hide");
      } else {
        td.classList.add("hide");
      }
    });
  }

  showHideColumnToggle(e) {
    let hiddenColumns = Array.from(this.tableTarget.getElementsByClassName("column-hide"));
    let showColumns = Array.from(this.tableTarget.getElementsByClassName("column-show"));
    if(hiddenColumns.length == 0) {
      e.currentTarget.classList.remove("current");
      showColumns.forEach(e => e.classList.add("column-hide"));
      showColumns.forEach(e => e.classList.remove("column-show"));
    } else {
      e.currentTarget.classList.add("current");
      hiddenColumns.forEach(e => e.classList.add("column-show"));
      hiddenColumns.forEach(e => e.classList.remove("column-hide"));
    }
  }

}
