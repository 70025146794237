import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["requestedNumber"];

  changeNumbers(event) {
    this.requestedNumberTarget.innerHTML = "";
    let carNumbers = event.target.selectedOptions[0].dataset.carNumbers || "[]";
    carNumbers = JSON.parse(carNumbers)
    if(carNumbers.length == 0) {
      carNumbers = this.oneToOneHundred();
    }
    carNumbers.forEach(carNumber => {
      let selectOption = document.createElement("option")
      selectOption.value = carNumber;
      selectOption.innerHTML = carNumber;
      this.requestedNumberTarget.append(selectOption)
    })
  }

  oneToOneHundred() {
    return Array.from({length: 100}, (_, i) => i + 1);
  }
  
}
