import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["resultsTable"];

  connect() {
    this.leagueId = this.resultsTableTarget.dataset.leagueId;
  }

  loadPointsTable(e) {
    e.preventDefault();
    const partial = e.currentTarget.dataset.partial;
    fetch(this.url(partial))
      .then(response => response.text())
      .then(html => {this.resultsTableTarget.innerHTML = html})
    return false;
  }

  url(partial) {
    return `/drivers/leagues/${this.leagueId}/${partial}`
  }

}
