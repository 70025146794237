import { Controller } from "stimulus";
import debounce from 'lodash/debounce'
import Croppie from 'croppie';

export default class extends Controller {

  static targets = [
    "usernameField",
    "userIdField",
    "userSelectOptions",
    "userSelect",
    "imageModal",
    "imageField",
    "imageCropper",
  ];

  connect() {
    this.search = debounce(this.search, 1000);
    if(this.hasImageCropperTarget) {
      this.setupCroppy();
    }
  }

  search = (e) => {
    const username = this.usernameFieldTarget.value;
    fetch(`/api/v1/users/search?search=${username}`)
      .then(response => response.json())
      .then(data => this.buildSelector(data));
  }

  selected(e) {
    const data = e.currentTarget.dataset;
    this.usernameFieldTarget.value = data["username"];
    this.userIdFieldTarget.value = data["id"];
    this.userSelectOptionsTarget.classList.add("hide");
  }

  buildSelector(data) {
    var el = this.userSelectOptionsTarget;
    el.textContent = "";
    data.forEach( (user) => {
      let div = document.createElement("div");
      div.setAttribute("data-id", user.id);
      div.setAttribute("data-username", user.username);
      div.setAttribute("data-action", "click->master-admin--news-feed-items#selected")
      div.textContent = `${user.username} #${user.discord_discriminator}`
      el.appendChild(div)
    });
    el.classList.remove("hide");
  }

  setupCroppy() {
    this.croppedImage = null;
    this.croppie = new Croppie(this.imageCropperTarget, {
      viewport: { width: 350, height: 350, type: 'square'},
      boundary: { width: 500, height: 500 },
      enableZoom: true,
      showZoomer: true,
      enableOrientation: false,
      mouseWheelZoom: 'ctrl'
    });
  }

  showCropperModal(event) {
    this.imageModalTarget.classList.remove("hide");
    var url = URL.createObjectURL(event.target.files[0]);
    this.croppie.bind({url: url});
  }

  saveCroppedImage() {
    this.croppie.result("base64").then((base64) => {
      this.imageFieldTarget.value = JSON.stringify({data: base64});
    });
    this.imageModalTarget.classList.add("hide");
  }

}

