import { Controller } from "stimulus";
import debounce from 'lodash/debounce'

export default class extends Controller {

  static targets = ["usernameField", "driverIdField", "userSelectOptions", "userSelect", "driverCategorySelect"];

  connect() {
    this.search = debounce(this.search, 1000);
  }

  search = (e) => {
    const username = this.usernameFieldTarget.value;
    const communityId = this.usernameFieldTarget.dataset["communityId"]
    fetch(`/api/v1/community_users/search?community_id=${communityId}&search=${username}&fake=false`)
      .then(response => response.json())
      .then(data => this.buildSelector(data));
  }

  selected(e) {
    const data = e.currentTarget.dataset;
    this.usernameFieldTarget.value = data["username"];
    this.driverIdFieldTarget.value = data["id"];
    this.userSelectOptionsTarget.classList.add("hide");
  }

  buildSelector(data) {
    var el = this.userSelectOptionsTarget;
    el.textContent = "";
    data.forEach( (user) => {
      let div = document.createElement("div");
      div.setAttribute("data-id", user.id);
      div.setAttribute("data-username", user.username);
      div.setAttribute("data-action", "click->community-admin--community-users#selected")
      div.textContent = user.username
      el.appendChild(div)
    });
    el.classList.remove("hide");
  }

  driverCategoryFilter(e) {
    e.target.form.submit();
  }

}

