import { Controller } from "stimulus";
import moment from 'moment';

export default class extends Controller {

  static targets = ["additionalInfoDiv", "advancedOptionsDiv"];

  toggleAdditionalInfo() {
    let div = this.additionalInfoDivTarget;
    div.classList.toggle("hide");
  }

  toggleAdvancedOptions() {
    let div = this.advancedOptionsDivTarget;
    div.classList.toggle("hide");
  }

}
