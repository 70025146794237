import { Controller } from "stimulus";
import moment from 'moment-timezone';

export default class extends Controller {
  static targets = ["datetime", "time", "date", "dateLink", "driverRequests", "leagueRequests", "raceRequests"];

  connect() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setLocaleCookie();
    this.setMomentLocale();
    this.setDateTimeSpans(timezone);
    this.setTimeSpans(timezone);
    this.setDateSpans(timezone);
    this.getDriverRequests();
  }

  setDateTimeSpans(timezone) {
    this.datetimeTargets.forEach((span) => {
      if(span.dataset.value) {
        var localtime = moment.utc(span.dataset.value).tz(timezone);
        span.innerHTML = localtime.format('ddd D MMM HH:mm');
        span.setAttribute("title", localtime.format('z'));
      }
    });
  }

  setTimeSpans(timezone) {
    this.timeTargets.forEach((span) => {
      if(span.dataset.value) {
        var localtime = moment.utc(span.dataset.value).tz(timezone);
        span.innerHTML = localtime.format('HH:mm');
        span.setAttribute("title", localtime.format('z'));
      }
    });
  }

  setDateSpans(timezone) {
    this.dateTargets.forEach((span) => {
      if(span.dataset.value) {
        var localtime = moment.utc(span.dataset.value).tz(timezone);
        if(localtime.format('D MMM') == moment().format('D MMM')) {
          span.innerHTML = "TODAY";
          span.classList.add("today");
        } else {
          span.innerHTML = localtime.format('D MMM');
          span.setAttribute("title", localtime.format('z'));
        }
      }
    });
  }

  setLocaleCookie() {
    let expDate=new Date();
    expDate.setDate(expDate.getDate() + 30)
    document.cookie = `locale=${navigator.language.substring(0,2)}; expires=${expDate}`
  }

  setMomentLocale() {
    const locale = document.querySelector('meta[http-equiv="content-language"]').content;
    moment.locale(locale)
  }

  setDateLinks(timezone) {
    this.dateLinkTargets.forEach((link) => {
      var localtime = moment.utc(link.dataset.value).tz(timezone);
      var localDate = localtime.format('YYYY-MM-DD')
      link.href = link.href.replace("today", localDate);
    });
  }

  getDriverRequests() {
    if(this.hasDriverRequestsTarget) {
      fetch("/api/v1/driver_requests_count").then(response => response.json()).then(data => {
        if(data.total > 0) {
          let totalSpan = document.createElement("span");
          totalSpan.classList.add("item-counter");
          totalSpan.textContent = data.total;
          this.driverRequestsTarget.prepend(totalSpan)
          if(this.hasLeagueRequestsTarget && data.league_requests > 0) {
            let leagueSpan = document.createElement("span");
            leagueSpan.classList.add("item-counter");
            leagueSpan.textContent = data.league_requests;
            this.leagueRequestsTarget.prepend(leagueSpan)
          }
          if(this.hasRaceRequestsTarget && data.race_requests > 0) {
            let raceSpan = document.createElement("span");
            raceSpan.classList.add("item-counter");
            raceSpan.textContent = data.race_requests;
            this.raceRequestsTarget.prepend(raceSpan)
          }
        }
      })
    }

  }

}
