const getParams = function() {

  function getSearchParameters() {
    var prmstr = window.location.search.substring(1);
    return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
  }

  function transformToAssocArray( prmstr ) {
    var params = {};
    var prmarr = prmstr.split("&");
    for ( var i = 0; i < prmarr.length; i++) {
      var tmparr = prmarr[i].split("=");
      var key = tmparr[0].replace("%5B", "[").replace("%5D","]");
      params[key] = tmparr[1];
    }
    return params;
  }

  return getSearchParameters();

}

const params = getParams();

export default params;
