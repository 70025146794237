import { Controller } from "stimulus";
import Croppie from 'croppie';

export default class extends Controller {
  static targets = [
    "imageModal",
    "imageField",
    "imageCropper",
  ];

  connect() {
    this.setupCroppy();
  }

  setupCroppy() {
    this.croppedImage = null;
    this.croppie = new Croppie(this.imageCropperTarget, {
      viewport: { width: 100, height: 100, type: 'square'},
      boundary: { width: 200, height: 200 },
      enableZoom: true,
      showZoomer: true,
      enableOrientation: false,
      mouseWheelZoom: 'ctrl'
    });
  }

  showCropperModal(event) {
    this.imageModalTarget.classList.remove("hide");
    var url = URL.createObjectURL(event.target.files[0]);
    this.croppie.bind({url: url});
  }

  saveCroppedImage() {
    this.croppie.result("base64").then((base64) => {
      this.imageFieldTarget.value = JSON.stringify({data: base64});
    });
    this.imageModalTarget.classList.add("hide");
  }

}
