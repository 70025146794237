import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  submitForm(e) {
    e.preventDefault();
    const url = this.formTarget.action;
    const formData = new FormData(this.formTarget);
    var filtersObject = Object.fromEntries(formData.entries());
    var mainFilters = this.getMainFilters(formData.get("main_filter"));
    var filters = {...filtersObject, ...mainFilters};
    filters = {...filters, ...this.myGamesAndPlatformsOnly(filters)}
    const filterString = new URLSearchParams(filters).toString();
    Turbolinks.visit(`${url}?${filterString}`);
  }

  clearForm(e) {
    e.preventDefault();
    const url = this.formTarget.action;
    const filterString = new URLSearchParams({}).toString();
    window.location.replace(`${url}?${filterString}`);
  }

  myGamesAndPlatformsOnly(filters) {
    if(!filters["platform_id"] && !filters["game_id"] && !filters["my_leagues"]) {
      return {driver_view: true};
    } else {
      return {}
    }
  }

  isEmpty(val){
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

  getMainFilters(filter) {
    switch(filter) {
      case "mine": return {my_leagues: true};
      case "pending": return {my_pending: true};
      case "community": return {communities: true};
      case "all": return {};
      case "all_games": return {all_games: true};
      case "completed": return {finished: true};
      default: return {my_leagues: true};
    }
  }

}
