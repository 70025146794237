import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {

  static targets = [
    "socialLinksDiv",
    "discordManagementDiv",
    "advancedOptionsDiv",
    "pointSystems",
    "pointSystemLi"
  ];

  connect() {
    this.sortable = Sortable.create(this.pointSystemsTarget, {
      onEnd: this.updatePointSystemPositions.bind(this)
    });
  }

  toggleDiscordManagement() {
    let div = this.discordManagementDivTarget;
    div.classList.toggle("hide");
  }

  toggleSocialLinks() {
    let div = this.socialLinksDivTarget;
    div.classList.toggle("hide");
  }

  toggleAdvancedOptions() {
    let div = this.advancedOptionsDivTarget;
    div.classList.toggle("hide");
  }

  updatePointSystemPositions(event) {
    const positions = Array.from(this.pointSystemLiTargets).map((li,index) => {
      return {"id": li.dataset.pointSystemId, "position": index}
    });
    fetch(this.pointSystemsTarget.dataset.url,
      {
        method: "POST",
        body: JSON.stringify({point_systems: {sort: positions}}),
        headers: { 'Content-Type': 'application/json'}
      })
      .then(function(res){ return res.json(); })
      .then(function(data){ console.log( JSON.stringify( data ) ) });
  }

}
