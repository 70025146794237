import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [
    "col",
    "tbody",
    "positionField",
    "positionSpan",
    "pointsField",
    "timeField",
    "unplacedInput"
  ];

  connect() {
    this.pointSystems = JSON.parse(this.tbodyTarget.dataset.pointSystems);
    this.sortable = Sortable.create(this.tbodyTarget, {
      delayOnTouchOnly: true,
      handle: ".draggable",
      onEnd: this.updateRows.bind(this)
    });
    this.updateRows();
  }

  updateRows(event) {
    if(this.isTiered()) {
      Object.keys(this.pointSystems).forEach((key) => {
        this.updatePositions(key);
      })
    } else {
      this.updatePositions(this.pointSystems["default"]["id"])
    }
  }

  updatePositions(key, index) {
    let pos = 1;
    this.positionFieldTargets.forEach((positionField) => {
      if(positionField.dataset.pointSystemId == key) {
        positionField.value = pos;
        pos++;
      }
    });
    pos = 1;
    this.positionSpanTargets.forEach((positionSpan) => {
      if(positionSpan.dataset.pointSystemId == key) {
        positionSpan.textContent = pos;
        pos++;
      }
    });
  }

  updateUnplaced(event) {
    const id = event.target.dataset.id;
    const name = event.target.value;
    const type = event.target.dataset.type;
    let inputTarget = this.unplacedInputTargets.find(input => input.dataset.id == id);
    let newName = `${type}[${name}][${id}]`;
    inputTarget.id = newName;
    inputTarget.name = newName;
    if(name == 'unplaced') {
      inputTarget.removeAttribute("value");
      inputTarget.name = "race[unplaced]";
      inputTarget.id = "race[unplaced]";
    } else {
      inputTarget.value = '1';
    }
  }

  checkTime(event) {
    if(event.currentTarget.value) {
      let times = event.currentTarget.value.split(/(\d+)/).filter(val => !isNaN(parseInt(val)));
      console.log(times);
      if(times.length == 3) {
        if(times[1].length == 1){
          times[1] = "0" + times[1];
        }
        if(times[2].length == 2) {
          times[2] = times[2] + "0"; 
        }
        if(times[2].length == 1) {
          times[2] = times[2] + "00";
        }
      }
    }
  }

  isTiered() {
    return Object.keys(this.pointSystems).length > 1;
  }

}
