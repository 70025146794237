import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [
    "col",
    "tbody",
    "positionField",
    "positionSpan",
    "pointsField",
    "tierPositionField",
    "tierPositionSpan",
    "qualifyingBody",
    "qualifyingPointsField",
    "qualifyingPositionField",
    "qualifyingPositionSpan",
    "tierQualifyingPositionField",
    "tierQualifyingPositionSpan",
    "fastestLap",
    "fastestLapPoint",
    "unplacedInput"
  ];

  connect() {
    this.pointSystems = JSON.parse(this.tbodyTarget.dataset.pointSystems);
    this.defaultPoints = JSON.parse(this.tbodyTarget.dataset.defaultPoints);
    this.updateDefaultSystem();
    this.updateTieredSystem();
    this.enableQualifying = this.colTarget.dataset.enableQualifying;
    this.sortable = Sortable.create(this.tbodyTarget, {
      delayOnTouchOnly: true,
      handle: ".draggable",
      onEnd: this.updateRows.bind(this)
    });
    if(this.enableQualifying == "true") { this.setupQualifyingSystem() }
  }

  setupQualifyingSystem() {
    this.defaultQualifyingPoints = JSON.parse(this.qualifyingBodyTarget.dataset.defaultQualifyingPoints);
    this.updateQualifyingRows();
    this.sortableQualifying = Sortable.create(this.qualifyingBodyTarget, {
      delayOnTouchOnly: true,
      handle: ".draggable",
      onEnd: this.updateQualifyingRows.bind(this)
    });
  }

  updateRows(event) {
    const self = this;
    if(this.isTiered()) {
      this.updateTieredSystem();
    } else {
      this.updateDefaultSystem();
    }
  }

  updateQualifyingRows(event) {
    if(this.isTiered()) {
      this.updateTieredQualifying();
    } else {
      this.updateDefaultQualifying();
    }
  }

  updateDefaultQualifying() {
    this.defaultQualifyingPoints.forEach( (value, index) => {
      let positionField = this.qualifyingPositionFieldTargets[index];
      let positionSpan = this.qualifyingPositionSpanTargets[index];
      let pointsField = this.qualifyingPointsFieldTargets[index];
      if(positionField !== undefined) {
        positionSpan.innerHTML = index + 1;
        positionField.value = index + 1;
        if(value > 0) {
          pointsField.value = value;
        } else {
          pointsField.value = null;
        }
      }
    });
  }

  updateDefaultSystem() {
    this.defaultPoints.forEach((value, index) => {
      let positionField = this.positionFieldTargets[index];
      let positionSpan = this.positionSpanTargets[index];
      let pointsField = this.pointsFieldTargets[index];
      if(positionField !== undefined) {
        positionSpan.innerHTML = index + 1;
        positionField.value = index + 1;
        pointsField.value = value;
      }
    });
  }

  isTiered() {
    return Object.keys(this.pointSystems).length > 1;
  }

  updateTieredSystem() {
    Object.keys(this.pointSystems).forEach((key) => {
      this.updateTieredPoints(key);
      this.updateTieredPositions(key);
      this.updateOverallPositions();
    });
  }

  updateTieredPoints(key) {
    const points = this.pointSystems[key]["main_points"];
    let pos = 0;
    this.pointsFieldTargets.forEach((pointsField) => {
      if(pointsField.dataset.pointSystemId == key) {
        pointsField.value = points[pos];
        pos++;
      }
    });
  }

  updateTieredPositions(key, index) {
    let pos = 1;
    this.tierPositionFieldTargets.forEach((tierPositionField) => {
      if(tierPositionField.dataset.pointSystemId == key) {
        tierPositionField.value = pos;
        pos++;
      }
    });
    pos = 1;
    this.tierPositionSpanTargets.forEach((tierPositionSpan) => {
      if(tierPositionSpan.dataset.pointSystemId == key) {
        tierPositionSpan.textContent = pos;
        pos++;
      }
    });
    this.updateOverallPositions()
  }

  updateOverallPositions() {
    this.positionFieldTargets.forEach((positionField, index) => {
      let positionSpan = this.positionSpanTargets[index];
      if(positionField !== undefined) {
        positionSpan.innerHTML = index + 1;
        positionField.value = index + 1;
      }
    });
  }

  updateTieredQualifying() {
    Object.keys(this.pointSystems).forEach(key => {
      this.updateTieredQualifyingPoints(key);
      this.updateTieredQualifyingPositions(key);
    });
    this.updateOverallQualifyingPositions();
  }

  updateTieredQualifyingPoints(key) {
    let pos = 0;
    const points = this.pointSystems[key]["qualifying_points"];
    this.qualifyingPointsFieldTargets.forEach((qualifyingPointsField) => {
      if(qualifyingPointsField.dataset.pointSystemId == key) {
        if(points[pos] > 0) {
          qualifyingPointsField.value = points[pos];
        } else {
          qualifyingPointsField.value = null;
        }
        pos++;
      }
    });
  }

  updateTieredQualifyingPositions(key) {
    let pos = 1;
    this.tierQualifyingPositionFieldTargets.forEach((tierQualifyingPositionField) => {
      if(tierQualifyingPositionField.dataset.pointSystemId == key) {
        tierQualifyingPositionField.value = pos;
        pos++;
      }
    });
    pos = 1;
    this.tierQualifyingPositionSpanTargets.forEach((tierQualifyingPositionSpan) => {
      if(tierQualifyingPositionSpan.dataset.pointSystemId == key) {
        tierQualifyingPositionSpan.textContent = pos;
        pos++;
      }
    });
  }

  updateOverallQualifyingPositions() {
    this.qualifyingPositionFieldTargets.forEach((positionField, index) => {
      let positionSpan = this.qualifyingPositionSpanTargets[index];
      if(positionField !== undefined) {
        positionSpan.innerHTML = index + 1;
        positionField.value = index + 1;
      }
    });

  }

  updateUnplaced(event) {
    const id = event.target.dataset.id;
    const name = event.target.value;
    const type = event.target.dataset.type;
    let inputTarget = this.unplacedInputTargets.find(input => input.dataset.id == id);
    let newName = `${type}[${name}][${id}]`;
    inputTarget.id = newName;
    inputTarget.name = newName;
    if(name == 'unplaced') {
      inputTarget.removeAttribute("value");
      inputTarget.name = "race[unplaced]";
      inputTarget.id = "race[unplaced]";
    } else {
      inputTarget.value = '1';
    }
    if(name !== 'unplaced') {
      var points = this.pointsFieldTargets.find((input) => {
        if(input.dataset.id == id) { return input }
      });
      if(points !== undefined) { points.value = 0 }
    }
  }

  updateFastestLapPoints(event) {
    if(this.hasFastestLapPointTarget) {
      let points = {};
      const fastestLapPoints = this.tbodyTarget.dataset.fastestLapPoints;
      this.fastestLapTargets.forEach(el => {
        if(el.value) {
          let times = el.value.split(/(\d+)/).filter(val => !isNaN(parseInt(val)));
          if(times.length == 3) {
            if(times[1].length == 1){
              times[1] = "0" + times[1];
            }
            if(times[2].length == 2) {
              times[2] = times[2] + "0"; 
            }
            if(times[2].length == 1) {
              times[2] = times[2] + "00";
            }
          }
          points[el.dataset.id] = parseInt(times.join(""));
        }
      });
      const sortedPoints = Object.entries(points).sort(([,a],[,b]) => a-b);
      if(sortedPoints[0]) {
        this.fastestLapPointTargets.forEach(el => {
          if(el.dataset.id == sortedPoints[0][0]) {
            el.value = fastestLapPoints;
          } else {
            el.value = null;
          }
        });
      }
    }
  }

}
