import { Controller } from "stimulus";
import debounce from 'lodash/debounce'

export default class extends Controller {

  static targets = ["newsItems", "pagination"]

  connect() {
    this.scroll = debounce(this.scroll, 1000);
  }

  scroll() {
    let url = this.paginationTarget.querySelector(".next_page").href;
    const body = document.body;
    const html = document.documentElement;
    const footer = document.getElementsByClassName("footer")[0];
    let footerHeight = footer ? footer.offsetHeight : 0;
    let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    height = height - window.innerHeight - footerHeight;
    if((window.pageYOffset >= height) && url) {
      this.loadMore(url);
    }
  }

  loadMore(url) {
    fetch(url,
      {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
      }
    ).then(response => response.json()
    ).then(data => {
      this.newsItemsTarget.insertAdjacentHTML("beforeend", data.newsItems);
      this.paginationTarget.innerHTML = data.pagination;
    })
  }




}
