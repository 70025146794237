import { Controller } from "stimulus";
import params from '../../helpers/params'

export default class extends Controller {
  static targets = [
    "mainPointsDisplay",
    "qualifyingPointsDisplay",
    "qualifyingPointsHeader",
    "enableQualifyingData",
    "maxDrivers",
    "existingPoints",
    "advancedOptionsDiv"
  ];

  connect() {
    const maxDrivers = this.maxDriversTarget.value;
    this.defaultMainPoints = JSON.parse(this.existingPointsTarget.dataset.mainpoints);
    this.defaultQualifyingPoints = JSON.parse(this.existingPointsTarget.dataset.qualifyingpoints);
    this.createMainPointsInputs(maxDrivers);
    if(this.enableQualifyingDataTarget.checked) {
      this.createQualifyingPointsInputs(maxDrivers);
    }
  }

  createMainPointsInputs(maxDrivers) {
    this.clearPreviousInputs();
    for (var i = 0; i <= (maxDrivers - 1); i++) {
      var defaultPoint = this.getMainPoint(i);
      let formGroup = document.createElement("div");
      formGroup.setAttribute("class", "input-group input-group-sm mb-3");
      let div = document.createElement("div");
      div.setAttribute("class", "input-group-prepend");
      let span = document.createElement("span")
      span.setAttribute("class", "input-group-text");
      const position = document.createTextNode(i+1);
      span.appendChild(position);
      div.appendChild(span)
      formGroup.appendChild(div)
      let input = document.createElement("input");
      input.setAttribute("type", "number");
      input.setAttribute("name", `point_system[main_points][]`);
      input.setAttribute("class", "form-control");
      input.setAttribute("value", defaultPoint);
      formGroup.appendChild(input);
      this.mainPointsDisplayTarget.appendChild(formGroup);
    }
  }

  createQualifyingPointsInputs(maxDrivers) {
    this.removeQualifyingPointsInputs();
    for (var i = 0; i <= (maxDrivers - 1); i++) {
      var defaultPoint = this.getQualifyingPoint(i);
      let formGroup = document.createElement("div");
      formGroup.setAttribute("class", "input-group input-group-sm mb-3");
      let div = document.createElement("div");
      div.setAttribute("class", "input-group-prepend");
      let span = document.createElement("span")
      span.setAttribute("class", "input-group-text");
      const position = document.createTextNode(i+1);
      span.appendChild(position);
      div.appendChild(span)
      formGroup.appendChild(div)
      let input = document.createElement("input");
      input.setAttribute("type", "number");
      input.setAttribute("name", `point_system[qualifying_points][]`);
      input.setAttribute("class", "form-control");
      input.setAttribute("value", defaultPoint);
      formGroup.appendChild(input);
      this.qualifyingPointsDisplayTarget.appendChild(formGroup);
    }
  }

  clearPreviousInputs() {
    while (this.mainPointsDisplayTarget.firstChild) {
      this.mainPointsDisplayTarget.removeChild(this.mainPointsDisplayTarget.firstChild);
    }
  }

  removeQualifyingPointsInputs() {
    while (this.qualifyingPointsDisplayTarget.firstChild) {
      this.qualifyingPointsDisplayTarget.removeChild(this.qualifyingPointsDisplayTarget.firstChild);
    }
  }

  updateMaxDrivers(e) {
    this.createMainPointsInputs(e.target.value);
    if(this.enableQualifyingDataTarget.checked) {
      this.createQualifyingPointsInputs(e.target.value);
    }
  }

  getMainPoint(i) {
    return (this.defaultMainPoints[i] || 0);
  }

  getQualifyingPoint(i) {
    return (this.defaultQualifyingPoints[i] || 0);
  }

  toggleQualifyingPoints() {
    let header = this.qualifyingPointsHeaderTarget;
    if(this.enableQualifyingDataTarget.checked){
      header.classList.remove("hide");
      this.createQualifyingPointsInputs(this.maxDriversTarget.value);
    } else {
      this.removeQualifyingPointsInputs();
      header.classList.add("hide");
    }
  }

  toggleAdvancedOptions() {
    let div = this.advancedOptionsDivTarget;
    if(div.classList.contains("hide")){
      div.classList.remove("hide");
    } else {
      div.classList.add("hide");
    }
  }


}
