import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  submit(e) {
    e.preventDefault();
    let form = this.formTarget;
    const dataSet = new FormData(form);
    let url = form.action;
    let community_id = dataSet.get("league[community_id]");
    if(community_id == null) { return };
    url = url.replace("temp", community_id);
    form.action = url;
    form.submit();
  }
}
