import { Controller } from "stimulus";
import debounce from 'lodash/debounce'

export default class extends Controller {

  static targets = ["usernameField", "driverIdField", "userSelectOptions", "userSelect"];

  connect() {
    this.search = debounce(this.search, 1000);
  }

  search = (e) => {
    const username = this.usernameFieldTarget.value;
    const communityId = this.usernameFieldTarget.dataset["communityId"]
    fetch(`/api/v1/users/search?community_id=${communityId}&search=${username}`)
      .then(response => response.json())
      .then(data => this.buildSelector(data));
  }

  selected(e) {
    const data = e.currentTarget.dataset;
    this.usernameFieldTarget.value = data["username"];
    this.driverIdFieldTarget.value = data["id"];
    this.userSelectOptionsTarget.classList.add("hide");
  }

  buildSelector(data) {
    var el = this.userSelectOptionsTarget;
    el.textContent = "";
    data.forEach( (user) => {
      let div = document.createElement("div");
      div.setAttribute("data-id", user.id);
      div.setAttribute("data-username", user.username);
      div.setAttribute("data-action", "click->community-admin--new-driver-league-request#selected")
      div.textContent = `${user.username} #${user.discord_discriminator}`
      el.appendChild(div)
    });
    el.classList.remove("hide");
  }

}

