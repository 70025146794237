import { Controller } from "stimulus";
import moment from 'moment';

export default class extends Controller {

  static targets = [
    "startDateTime",
    "dateTimeView",
    "dateTimeLabel",
    "raceForm",
    "additionalInfoDiv",
    "advancedOptionsDiv"
  ];

  connect() {
    this.timezoneText = moment.tz.guess();
    var dateTime = this.startDateTimeTarget.value;
    if(dateTime) { this.setDateTime(dateTime) };
    this.setDateTimeLabel();
  }

  setDateTime(dateTime) {
    var localTime = moment.utc(dateTime).tz(this.timezoneText).format('YYYY-MM-DDTHH:mm:ss');
    this.dateTimeViewTarget.value = localTime;
  }

  setDateTimeLabel() {
    var text = this.dateTimeLabelTarget.innerHTML;
    text += ` (${this.timezoneText})`
    this.dateTimeLabelTarget.innerHTML = text;
  }

  submitForm(e) {
    e.preventDefault();
    var localtime = moment(this.dateTimeViewTarget.value).tz(this.timezoneText);
    this.startDateTimeTarget.value = moment.utc(localtime).format('YYYY-MM-DDTHH:mm:ss');
    this.raceFormTarget.submit();
  }

  toggleAdditionalInfo() {
    let div = this.additionalInfoDivTarget;
    if(div.classList.contains("hide")){
      div.classList.remove("hide");
    } else {
      div.classList.add("hide");
    }
  }

  toggleAdvancedOptions() {
    let div = this.advancedOptionsDivTarget;
    if(div.classList.contains("hide")){
      div.classList.remove("hide");
    } else {
      div.classList.add("hide");
    }
  }

}
