import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["alert"];

  close() {
    this.alertTargets.forEach(target => {
      target.remove();
      target.remove();
    });
  }

}
