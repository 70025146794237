import { Controller } from "stimulus";
import Croppie from 'croppie';
import debounce from 'lodash/debounce';

export default class extends Controller {
  static targets = [
    "mainForm",
    "imageModal",
    "imageField",
    "imageCropper",
    "searchForm",
    "search",
    "deniedDriversTable"
  ];

  connect() {
    if(this.hasSearchFormTarget) {
      this.search = debounce(this.search, 1000);
    }
    if(this.hasImageCropperTarget) {
      this.setupCroppy();
    }
  }

  search(event) {
    const url = this.searchFormTarget.action;
    const search = event.target.value;
    window.location=`${url}?search=${search}`
  }

  copyLink(event) {
    event.preventDefault;
    const copyText = event.currentTarget.href;
    navigator.clipboard.writeText(copyText);
    alert(event.currentTarget.dataset.text + ": " + copyText);
    return false;
  }

  toggleDeniedDrivers() {
    let table = this.deniedDriversTableTarget;
    if(table.classList.contains("hide")){
      table.classList.remove("hide");
    } else {
      table.classList.add("hide");
    }
  }

  setupCroppy() {
    this.croppedImage = null;
    this.croppie = new Croppie(this.imageCropperTarget, {
      viewport: { width: 350, height: 350, type: 'square'},
      boundary: { width: 500, height: 500 },
      enableZoom: true,
      showZoomer: true,
      enableOrientation: false,
      mouseWheelZoom: 'ctrl'
    });
  }

  showCropperModal(event) {
    this.imageModalTarget.classList.remove("hide");
    var url = URL.createObjectURL(event.target.files[0]);
    this.croppie.bind({url: url});
  }

  saveCroppedImage() {
    this.croppie.result("base64").then((base64) => {
      this.imageFieldTarget.value = JSON.stringify({data: base64});
    });
    this.imageModalTarget.classList.add("hide");
  }

}