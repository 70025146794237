import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [
    "tbody",
    "row",
    "stage"
  ];

  connect() {
    this.communityId = this.tbodyTarget.dataset.communityId;
    this.sortable = Sortable.create(this.tbodyTarget, {
      delayOnTouchOnly: true,
      handle: ".draggable",
      onEnd: this.updateRows.bind(this)
    });
  }

  updateRows(event) {
    const ids = this.rowTargets.map(row => row.dataset["id"])
    const payload = {ids: ids};
    var data = new FormData();
      data.append( "json", JSON.stringify( payload ) );
      fetch(`/community_admin/communities/${this.communityId}/rally_stages/order_stages`,
      {
        method: "POST",
        body: data
      })
    .then( res => { return res.json(); })
    .then( data => { 
      data.forEach(st => {
        let stage = this.stageTargets.find(t => t.dataset.id == st["id"]);
        stage.innerHTML = st.number;
      })
    })
  }

}
